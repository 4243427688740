import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"align-center"},[_c(VIcon,{staticClass:"mx-3",attrs:{"color":"primary"}},[_vm._v("location_pin")]),_c('span',[_vm._v(" "+_vm._s(((_vm.value.locality) + ", " + (_vm.value.administrative_area) + " " + (_vm.value.postal_code)))+" ")]),(_vm.value.is_default)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"mx-3",attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v("star")])]}}],null,false,4176474327)},[_c('span',[_vm._v(_vm._s(_vm.$t('This is default address')))])]):_vm._e(),_c(VSpacer),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }