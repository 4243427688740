import type { CustomerAddress } from '@futureecom/futureecom-js/dist/services/customer-service'

export default (value: CustomerAddress, addresses: CustomerAddress[]): CustomerAddress[] => {
  const updateAddress = (item: CustomerAddress) => (item.id === value.id ? value : item)

  if (value.is_default) {
    return addresses.map((item) => ({ ...item, is_default: false })).map(updateAddress)
  }

  return addresses.map(updateAddress)
}
