import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-space-between"},[_c('span',{staticClass:"grey--text text--darken-2"},[_vm._v(_vm._s(_vm.$t('Addresses')))]),_c('customer-address-create',{attrs:{"value":_vm.value},on:{"created":_vm.onAddressCreated},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [(_vm.canCreate)?_c(VBtn,_vm._g(_vm._b({attrs:{"depressed":"","small":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('Add address'))+" ")]):_vm._e()]}}])})],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.addresses,"hide-default-footer":"","item-key":"key","items-per-page":-1},scopedSlots:_vm._u([{key:"item.address",fn:function(ref){
var item = ref.item;
return [_c('address-line',{attrs:{"value":item}})]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('customer-address-update',{attrs:{"value":item,"customer":_vm.value},on:{"updated":_vm.onAddressUpdate},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('button-action',_vm._g(_vm._b({attrs:{"icon-name":"edit","icon":"","label":_vm.$t('Edit')}},'button-action',attrs,false),on))]}}],null,true)})]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [(item.id && !item.is_default)?_c('address-action-delete',{attrs:{"icon":"","customer":_vm.value,"value":item.id},on:{"success":function($event){return _vm.onAddressDelete(item.id)}}}):(!item.is_default)?_c('delete-button',{attrs:{"suppress-confirmation":"","icon":true},on:{"click":function($event){return _vm.onAddressDelete(item.id)}}}):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }